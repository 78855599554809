<template>
    <section class="op-productos-config">
        <navbar-admin>
            <el-tooltip content="Eliminar" placement="bottom-end" effect="light" visible-arrow>
                <button class="btn btn-square32-5d ml-2 cr-pointer" @click="deleteConfiguration">
                    <i class="icon-trash-can-outline text-white" />
                </button>
            </el-tooltip>
        </navbar-admin>
        <!-- nombre -->
        <div class="row">
            <div class="col-12 col-lg-9 mb-3 mb-lg-0">
                <div class="bg-f9 border-dd br-4 px-3 h-52px d-middle">
                    <div class="row mx-0 w-100">
                        <div class="col my-auto">
                            <p class="f-600">{{ configuracion.configuracion }}</p>
                        </div>
                        <div class="col-auto my-auto ml-auto pr-0">
                            <div class="d-flex">
                                <el-tooltip content="Cantidad y valor de los materiales" placement="bottom" effect="light" visible-arrow>
                                    <i class="icon-format-list-numbered f-18 mr-1" />
                                </el-tooltip>
                                <p class="my-auto f-14">{{ configuracion.materiales }} - $ {{formatoMoneda(configuracion.valor_total_materiales)}}</p>
                            </div>
                        </div>
                        <div class="col-auto my-auto ml-auto pr-0">
                            <div class="d-middle">
                                <i class="icon-account-clock f-18 mr-1" />
                                <div class="my-auto f-14 d-middle">
                                    {{ configuracion.horas_hombre }} Hrs. - $ <el-input v-model="valorHoraHombre" maxlength="7" size="small" class="mx-2" style="max-width: 115px;" @change="actualizarValorHorasHombre" @keypress.native.enter="actualizarValorHorasHombre"/>
                                </div>
                                <el-tooltip content="Restablecer precio original" effect="light" placement="right" visible-arrow>
                                    <div class="icon-option">
                                        <i class="icon-history f-20 cr-pointer" @click="setDefaultPrice" />
                                    </div>
                                </el-tooltip>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col col-lg-3">
                <div class="bg-f9 border br-4 px-3 d-middle-center h-52px">
                    <p class="f-600"> {{ formatoMoneda(configuracion.total_final) }}</p>
                </div>
            </div>
        </div>
        <!-- add material -->
        <div class="row mb-3 mt-4">
            <div class="col-12 col-lg-auto my-auto">
                <p class="f-14 f-600">Añadir material</p>
            </div>
            <div class="col-12 col-lg-4 position-relative my-auto">
                <p class="input-label-top">Nombre del material</p>
                <el-select v-model="mat" filterable reserve-keyword placeholder="Nombre del material" size="small" class="w-100" @change="getAmount" >
                    <el-option v-for="item in selectMateriales" :key="item.id" :label="item.material" :value="item.id"/>
                </el-select>
            </div>
            <div class="col-12 col-lg-2 position-relative my-auto">
                <p class="input-label-top">Unidades</p>
                <el-input v-model="inputCantUnidades" placeholder="Cant unidades" size="small" class="w-100" />
            </div>
            <!-- agregar material -->
            <div class="col-auto icon-option my-auto px-1">
                <el-tooltip slot="reference" content="Añadir material" placement="bottom" effect="light" visible-arrow>
                    <i class="icon-plus-circle f-26 cr-pointer" @click="agregarMaterial"/>
                </el-tooltip>
            </div>
            <!-- importar excel -->
            <div class="col-auto icon-option my-auto px-1 d-none">
                <el-tooltip content="Importar excel con lista de materiales" placement="bottom" effect="light" visible-arrow>
                    <i class="icon-upload-outline f-26 cr-pointer" />
                </el-tooltip>
            </div>
            <!-- buscar en tabla -->
            <div class="col-auto icon-option ml-auto my-auto px-1">
                <el-input v-model="filtro.query" placeholder="Buscar material en la lista" size="small" class="w-100" @keypress.native.enter="listarMaterialesConfiguracion" />
            </div>
            <!-- abrir filtros -->
            <div class="col-auto icon-option my-auto pl-0">
                <el-tooltip content="Filtro" placement="bottom" effect="light" visible-arrow>
                    <button class="btn btn-square32-5d ml-2 cr-pointer" @click="abrirModalFiltros">
                        <i class="icon-filter text-white" />
                    </button>
                </el-tooltip>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-2" />
            <div class="col-12 col-lg-8 my-auto">
                <div class="row justify-content-center">
                    <div class="col-auto">
                        <div class="d-middle">
                            <p class="f-12">Configuraciones generales</p>
                            <i class="icon-information-outline f-22 text-general" />
                        </div>
                    </div>
                    <div class="col-auto">
                        <div class="d-middle">
                            <p class="f-12">Configuraciones personalizadas</p>
                            <i class="icon-information-outline f-22 text-confi-per" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-2">
                <button class="br-5 btn-general lh-15 py-2 f-12" @click="reestablecerMantenimientos"> Reestablecer los mantenimientos </button>
            </div>
        </div>
        <!-- <tabla-general :mostrarBuscador="false" alturaTabla="500px" :data="listadoConfiguracion"> -->
        <tabla-general :mostrarBuscador="false" alturaTabla="500px" :usarServidor="true" :usarPaginacion="true" :servidorData="materialesConfiguracion" @paginar="listarMaterialesConfiguracion">
            <el-table-column label="Ref" prop="ref" width="100">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 tres-puntos"> {{ scope.row.referencia }}</p>
                </template>
            </el-table-column>
            <el-table-column label="Nombre" prop="nombre">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 tres-puntos">{{ scope.row.nombre }}</p>
                </template>
            </el-table-column>
            <el-table-column label="Tipo" prop="tipo" width="100">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 tres-puntos">{{ scope.row.tipo_material }}</p>
                </template>
            </el-table-column>
            <el-table-column label="U.M." prop="um" width="80">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 tres-puntos">{{ scope.row.sigla }}</p>
                </template>
            </el-table-column>
            <el-table-column label="Valor cotizado" prop="valorCotizado">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 tres-puntos">{{ formatoMoneda(scope.row.valor_cotizado) }}</p>
                </template>
            </el-table-column>
            <el-table-column label="Unidades" prop="unidades" width="90">
                <template slot-scope="scope">
                    <el-input v-model="scope.row.cantidad" size="small" maxlength="7" :disabled="!editMode" @change="actualizarMaterial(scope.row)" @keypress.native.enter="actualizarMaterial(scope.row)"/>
                </template>
            </el-table-column>
            <el-table-column label="Plan de mantenimiento" prop="planMantenimiento">
                <template slot-scope="scope">
                    <el-select v-model="scope.row.id_plan_mantenimiento" placeholder="Seleccionar plan" size="small" :disabled="!editMode" @change="actualizarMaterial(scope.row)">
                        <el-option v-for="item in scope.row.planes_mantenimiento" :key="item.id" :label="item.nombre" :value="item.id" />
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column width="50">
                <template slot="header">
                    <el-tooltip content="Editar" effect="light" visible-arrow>
                        <i class="icon-pencil f-20 cr-pointer" @click="editMode = !editMode" />
                    </el-tooltip>
                </template>
                <template slot-scope="scope">
                    <i v-if="editMode" class="icon-trash-can-outline f-20 text-general " @click="eliminarMaterial(scope.row)" />
                    <!-- <i v-else class="icon-information-outline f-20 text-general" /> -->
                    <i v-else :class="scope.row.tipo_configuracion == 1 ? 'icon-information-outline f-20 text-general' : 'icon-information-outline f-22 text-confi-per'" />
                    <!-- <el-tooltip content="Eliminar" effect="light" visible-arrow>
                        <i class="f-20 text-general" :class="iconMode" @click="eliminarMaterial" />
                    </el-tooltip> -->
                    <!-- <div v-if="editMode">
                        <div v-if="editMode">
                        </div>
                    </div>
                    <div v-else>
                        <div v-if="!editMode">
                            <el-tooltip content="Estado" effect="light" visible-arrow>
                                <i v-if="!editMode" class="icon-information-outline f-20 text-general" />
                            </el-tooltip>
                        </div>
                    </div> -->
                </template>
            </el-table-column>
        </tabla-general>
        <!-- modales -->
        <modal-eliminar ref="deleteConfiguration" title="Eliminar configuración" mensaje="¿Está seguro que quiere eliminar esta configuración?" @delete="deleteConfiguracion"/>
        <modal-eliminar ref="abrirModalEliminarConfiguracion" title="Eliminar configuración" mensaje="¿Está seguro que quiere eliminar esta configuración?" @delete="deleteMaterial"/>
        <modal-lateral ref="abrirModalFiltros" titulo="Filtro">
            <div class="row mx-0 justify-content-center my-2">
                <div class="col-11 my-4">
                    <p class="input-label-top">Tipo de material</p>
                    <el-select v-model="filtro.id_tipo_material" placeholder="Seleccionar material" size="small" class="w-100">
                        <el-option v-for="item in filtroConfiguracion.tipos_materiales" :key="item.value" :label="item.tipo_material" :value="item.id" />
                    </el-select>
                </div>
                <div class="col-11 my-4">
                    <p class="input-label-top">Unidad de medida</p>
                    <el-select v-model="filtro.id_unidad_medida" placeholder="Seleccionar U.M" size="small" class="w-100">
                        <el-option v-for="item in filtroConfiguracion.unidades_medida" :key="item.value" :label="item.unidad_medida" :value="item.id" />
                    </el-select>
                </div>
                <div class="col-11 my-4">
                    <p class="input-label-top">Rango de valor</p>
                    <el-slider v-model="range_value" :disabled="get_range_value[0] === get_range_value[1]" range :min="get_range_value[0]" :max="get_range_value[1]" :format-tooltip="formatTooltipRange" />
                </div>
            </div>
            <div class="botones">
                <button type="button" class="btn mx-2 btn-limpiar f-12 h-32px" @click="limpiarFiltroConfiguracion"> Limpiar </button>
                <button type="button" class="btn mx-2 btn-general w-80px f-12 h-32px text-white"  @click="listarMaterialesConfiguracion"> Filtrar </button>
            </div>
        </modal-lateral>
    </section>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { Notification } from 'element-ui'
export default {
    data(){
        return{
            visibleAgregarMaterialConf: false,
            valorHoraHombre:'',
            visible: false,
            inputNombreMaterial: '',
            inputCantUnidades: '',
            inputNombreConfig: '',
            inputBuscarMateriales: '',
            inputCantUnidadesTable: '250',
            listadoPlanMantenimiento: '',
            editMode: false,
            listadoConfiguracion:[],
            infoConfiguracion: {
                idOportunidad:this.$route.params.idOportunidad,
                idProducto:this.$route.params.idProducto,
                idConfiguracion:this.$route.params.idConfiguracion,
                tabla:this.$route.params.tabla
            },
            dataMaterial:{},
            mat:''
        }
    },
    computed: {
        ...mapGetters({
            configuracion: 'oportunidades/oportunidades/configuracion',
            materialesConfiguracion: 'oportunidades/oportunidades/materialesConfiguracion',
            selectMateriales: 'oportunidades/oportunidades/selectMateriales',
            oportunidadesInformacion: 'oportunidades/oportunidades/oportunidadesInformacion',
            filtroConfiguracion: 'oportunidades/oportunidades/filtroConfiguracion',
            filterConfiguracion: 'oportunidades/oportunidades/filterConfiguracion',
        }),
        filtro:{
            get(){ return this.filterConfiguracion },
            set(val){ this.set_filter_configuracion(val)}
        },
        range_value:{
            get(){
                return [this.filtro.valor_minimo, this.filtro.valor_maximo]
            },
            set(val){
                this.filtro.valor_minimo = val[0]
                this.filtro.valor_maximo = val[1]
            },
        },
        get_range_value(){
            return [
                this.filtroConfiguracion?.rango_valor?.[0] ?? 0,
                this.filtroConfiguracion?.rango_valor?.[1] ?? 0
            ]
        },
        iconMode(){
            return this.editMode ? 'icon-trash-can-outline' : 'icon-information-outline'
        }
    },

    async created () {
        await this.listarFiltrosConfiguracion()
        this.limpiarFiltroConfiguracion()
        await this.listarConfiguracion()
        await this.listarMaterialesConfiguracion()
        await this.selectMats()
        await this.Action_get_opportunity_information(this.$route.params.idOportunidad)
        this.getBreadcumbs(['oportunidades.main']);
        this.migasPan()
    },
    methods:{
        ...mapActions({
            Action_get_configuracion: 'oportunidades/oportunidades/Action_get_configuracion',
            Action_get_materiales_configuracion: 'oportunidades/oportunidades/Action_get_materiales_configuracion',
            Action_put_actualizar_valor_horas_hombre: 'oportunidades/oportunidades/Action_put_actualizar_valor_horas_hombre',
            Action_put_restablecer_valor_horas_hombre: 'oportunidades/oportunidades/Action_put_restablecer_valor_horas_hombre',
            Action_put_actualizar_material: 'oportunidades/oportunidades/Action_put_actualizar_material',
            Action_delete_material: 'oportunidades/oportunidades/Action_delete_material',
            Action_get_select_materiales: 'oportunidades/oportunidades/Action_get_select_materiales',
            Action_post_agregar_materiales: 'oportunidades/oportunidades/Action_post_agregar_materiales',
            Action_post_reestablecer_mantenimientos: 'oportunidades/oportunidades/Action_post_reestablecer_mantenimientos',
            Action_delete_configuracion: 'oportunidades/oportunidades/Action_delete_configuracion',
            Action_get_opportunity_information: 'oportunidades/oportunidades/Action_get_opportunity_information',
            Action_get_data_filtro_configuracion: 'oportunidades/oportunidades/Action_get_data_filtro_configuracion',
            Action_clear_filter_configuracion: 'oportunidades/oportunidades/Action_clear_filter_configuracion',
            getBreadcumbs: 'navigation/breadcumbs/getBreadcumbs',
            spliceBreadcumbs: 'navigation/breadcumbs/spliceBreadcumbs',
            replaceBreadcumb: 'navigation/breadcumbs/replaceBreadcumb',
        }),
        ...mapMutations({
            set_filter_configuracion: 'oportunidades/oportunidades/set_filter_configuracion',

        }),
        migasPan() {
            this.spliceBreadcumbs([
                {
                    position: 1,
                    breadcumb: {
                        name: this.oportunidadesInformacion.nombre_oportunidad,
                        route: 'oportunidades.ver.productos',
                        params: { id: this.$route.params.idOportunidad, tipo: 'vigentes' },
                    }
                },
                {
                    position: 2,
                    breadcumb: {
                        name: this.configuracion.configuracion,
                        route: 'oportunidades.ver.productos.config',
                        params: {
                            idOportunidad: this.$route.params.idOportunidad,
                            idProducto: this.$route.params.idProducto,
                            idConfiguracion: this.$route.params.idConfiguracion,
                            tabla: this.$route.params.tabla
                        },
                    }
                }
            ])
            // this.replaceBreadcumb({ position: 2, breadcumb: this.$route.name })
        },
        async listarFiltrosConfiguracion(){
            const payload = {
                idOportunidad: this.$route.params.idOportunidad,
                idProducto: this.$route.params.idProducto,
                idConfiguracion: this.$route.params.idConfiguracion,
                tabla: this.$route.params.tabla
            }
            await this.Action_get_data_filtro_configuracion(payload)
        },
        async agregarMaterial(){
            if (!this.mat || !this.inputCantUnidades) {
                new Notification({
                    message: 'El campo nombre y unidades son requeridos',
                    type: 'error',
                    dangerouslyUseHTMLString: false
                })
                return
            }
            const payload = {
                id_material:this.mat,
                cantidad:this.inputCantUnidades,
                tabla:this.infoConfiguracion.tabla
            }
            await this.Action_post_agregar_materiales({params:this.infoConfiguracion, payload})
            await this.listarConfiguracion()
            await this.listarMaterialesConfiguracion()
            await this.selectMats()
            this.mat = ''
            this.inputCantUnidades = ''
        },
        getAmount(id){
            this.inputCantUnidades = this.selectMateriales?.find(e => e.id === id)?.cantidad ?? 0
        },
        async listarConfiguracion(){
            await this.Action_get_configuracion(this.infoConfiguracion)
            this.valorHoraHombre = this.configuracion.valor_hora_hombre
        },
        async listarMaterialesConfiguracion(page=1){
            await this.Action_get_materiales_configuracion({...this.infoConfiguracion, page})
        },
        async actualizarValorHorasHombre(){
            const payload = {
                id: this.configuracion.id,
                valor_hora_hombre: this.valorHoraHombre,
                tabla: this.configuracion.tabla,
            }
            await this.Action_put_actualizar_valor_horas_hombre({params:this.infoConfiguracion, payload})
            await this.listarConfiguracion()
        },

        deleteConfiguration(){
            this.$refs.deleteConfiguration.toggle()
        },
        async deleteConfiguracion(){
            const payload = {
                id:this.configuracion.id,
                tabla:this.configuracion.tabla,
            }
            await this.Action_delete_configuracion({params:this.infoConfiguracion, payload})
            this.$router.push({ name: 'oportunidades.ver.productos',
                params: {
                    id: this.$route.params.idOportunidad,
                    tipo:'vigentes'
                }
            })
        },
        abrirModalFiltros(){
            this.$refs.abrirModalFiltros.toggle()
        },
        formatTooltipRange(val){
            return this.formatoMoneda(val)
        },
        guardarNuevoMaterialConfig(){
            this.visibleAgregarMaterialConf = !this.visibleAgregarMaterialConf
        },
        eliminarMaterial(value){
            this.dataMaterial = {
                id:value.id_oportunidades_equipos_modelos_etapas_materiales,
                tabla:value.tabla
            }
            this.$refs.abrirModalEliminarConfiguracion.toggle()
        },
        async deleteMaterial(){
            await this.Action_delete_material({params:this.infoConfiguracion, payload:this.dataMaterial})
            await this.listarMaterialesConfiguracion()
            await this.listarConfiguracion()
            await this.selectMats()
            this.dataMaterial = {}
        },
        async setDefaultPrice(){
            const payload = {
                id: this.configuracion.id,
                tabla: this.configuracion.tabla,
            }
            await this.Action_put_restablecer_valor_horas_hombre({params:this.infoConfiguracion, payload})
            await this.listarConfiguracion()
        },
        async actualizarMaterial(value){
            const payload = {
                id:value.id_oportunidades_equipos_modelos_etapas_materiales,
                cantidad:value.cantidad,
                id_mantenimiento:value.id_plan_mantenimiento,
                tabla:value.tabla
            }
            await this.Action_put_actualizar_material({params:this.infoConfiguracion, payload})
            await this.listarConfiguracion()
            await this.listarMaterialesConfiguracion()

        },
        async selectMats(){
            await this.Action_get_select_materiales(this.infoConfiguracion)
        },
        guardarSettings(){
        },
        async reestablecerMantenimientos(){
            if (!this.materialesConfiguracion.data.length) {
                new Notification({
                    message: 'No existen materiales',
                    type: 'error',
                    dangerouslyUseHTMLString: false
                })
                return
            }
            await this.Action_post_reestablecer_mantenimientos(this.infoConfiguracion);
            await this.listarMaterialesConfiguracion()
        },
        async limpiarFiltroConfiguracion(){
            this.Action_clear_filter_configuracion({min:this.filtroConfiguracion?.rango_valor?.[0] ?? 0, max:this.filtroConfiguracion?.rango_valor?.[1] ?? 0})
            await this.listarMaterialesConfiguracion()
        }
    }

}
</script>

<style lang="scss" scoped>
.op-productos-config{
    .h-52px{
        height: 53px;
    }
    .icon-option{
        .icon-plus-circle, .icon-text-box-plus-outline, .icon-upload-outline, .icon-trash-can-outline{
            &:hover{
                color: var(--color-general);
            }
        }
    }
    .icon-trash-can-outline{
        &:hover{
            color: var(--color-general);
        }
    }
    .input-label-top{
        position: absolute;
        top: -15px;
    }
    .text-confi-per{
        color: #E1C71B;
    }
}
</style>